<template>
  <v-dialog v-model="headDialog" max-width="500" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update {{ name }}</v-card-title>
      <v-card-text>
        <v-switch
            v-model="add.head_office"
            :label="`Is this branch representing your head office?: ${add.head_office ? ' Yes':' No'}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        {{ myPix }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.add.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils";
import {validationMixin} from "vuelidate";
import {required,} from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    headDialog: Boolean,
    myId: {type: Number},
    name: {type: String}
  },
  data() {
    return {
      disabled: false,
      myPix: null,
      pix: 0,
      loading: false,
      regionlist: [],
      districtlist: [],
      add: {
        head_office: false
      },
    };
  },
  validations: {
    add: {
      head_office: {
        required,
      }
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.add.head_office = response.data.head_office;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeHead", payload);
    },
    submit() {
      let head_office = this.add.head_office;
      let data = {
        head_office
      };
      console.log("data:", data);
      let payload = {id: this.myId, data: data, state: false};
      this.$emit("submitHead", payload);
    },
  },
};
</script>
