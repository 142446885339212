<template>
  <v-dialog v-model="viewDialog" max-width="700" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View: {{ name }}</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">name</div>
              <div class="myright">{{ subsidiary.name | capitalize }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ subsidiary.description }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">manager</div>
              <div class="myright">{{ subsidiary.manager | capitalize }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">physical location</div>
              <div class="myright">
                {{ subsidiary.physical_location | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">country</div>
              <div class="myright">
                {{ subsidiary.countryName | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">region/state</div>
              <div class="myright">
                {{ subsidiary.regionsName | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">district</div>
              <div class="myright">
                {{ subsidiary.districtsName | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">phone</div>
              <div class="myright">{{ subsidiary.phone }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">mobile phone</div>
              <div class="myright">{{ subsidiary.mobile_phone }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">subsidiary type Name</div>
              <div class="myright">
                {{ subsidiary.subsidiary_typeName | capitalize }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">Head Office</div>
              <div class="myright">
                {{ subsidiary.head_office ? "Yes" : "No" }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">last updated</div>
              <div class="myright">
                {{ subsidiary.updated | myDating }}
              </div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">active</div>
              <div class="myright">
                {{ subsidiary.active ? "Yes" : "No" }}
              </div>
              <div class="clearall"></div>
            </li>
          </ul>
        </div>
        <br/>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    myId: {type: Number, default: 0},
    name: {type: String, default: null},
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      subsidiary: {},
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.subsidiary = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeView", payload);
    },
  },
};
</script>
