<template>
  <v-dialog v-model="removeDialog" max-width="400" persistent>
    <v-card>
      <v-card-title>remove subsidiary</v-card-title>
      <v-card-text>
        Are you sure you want to permanently remove this item?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
export default {
  props: {
    removeDialog: Boolean,
    myId: {type: Number},
  },
  data() {
    return {
      create: {
        service: null,
        description: null,
      },
    };
  },
  created() {
  },
  methods: {
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeRemove", payload);
    },
    submit() {
      let payload = {id: this.myId, state: false};
      this.$emit("submitRemove", payload);
    },
  },
};
</script>
