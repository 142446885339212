<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new subsidiary</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-select
                v-model="add.regionsid"
                :items="regionlist"
                :loading="loading"
                dense
                item-text="name"
                item-value="id"
                label="region/state"
                loader-height="1"
                return-object
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
                v-model="add.districtsid"
                :items="districtlist"
                dense
                item-text="name"
                item-value="id"
                label="district"
            ></v-select>
          </v-col>
        </v-row>
        <v-text-field v-model="add.name" dense label="name"></v-text-field>
        <v-textarea v-model="add.description" dense label="description" outlined
                    rows="3"
                    style="font-size: .9em"></v-textarea>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="add.mobile_phone"
                dense
                label="mobile phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="add.phone"
                dense
                label="phone"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="add.physical_location"
                dense
                label="physical location"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="add.manager"
                dense
                label="manager"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.add.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils";
import {validationMixin} from "vuelidate";
import {decimal, maxLength, minLength, required,} from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    newDialog: Boolean,
    subType: {type: Number},
  },
  data() {
    return {
      loading: false,
      regionlist: [],
      districtlist: [],
      add: {
        countryid: 1,
        regionsid: null,
        districtsid: null,
        name: null,
        manager: null,
        description: null,
        phone: null,
        mobile_phone: null,
        physical_location: null,
      },
    };
  },
  validations: {
    add: {
      countryid: {
        required,
      },
      regionsid: {
        required,
      },
      districtsid: {
        required,
      },
      name: {
        required,
      },
      description: {
        required,
      },
      phone: {
        maxLength: maxLength(10),
        minLength: minLength(10),
        decimal,
      },
      mobile_phone: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
        decimal,
      },
      physical_location: {
        required,
      },
      manager: {
        required,
      },
    },
  },
  watch: {
    "add.regionsid": function (i) {
      if (i.id > 0) {
        console.log(i.id);
        this.districts(i.id);
      }
    },
  },
  created() {
    this.regions();
  },
  methods: {
    regions() {
      this.loading = true;
      let self = this;
      Restful.general.regions
          .byCountry(this.add.countryid)
          .then((response) => {
            console.log(response.data);
            this.regionlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    districts(id) {
      this.loading = true;
      let self = this;
      Restful.general.district
          .byRegion(id)
          .then((response) => {
            console.log(response.data);
            this.districtlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {state: false};
      this.$emit("closeNew", payload);
    },
    submit() {
      let subsidiary_typeid = parseInt(this.subType);
      let countryid = this.add.countryid;
      let regionsid = this.add.regionsid.id;
      let districtsid = this.add.districtsid;
      let name = this.add.name;
      let manager = this.add.manager;
      let description = this.add.description;
      let phone = this.add.phone;
      let mobile_phone = this.add.mobile_phone;
      let physical_location = this.add.physical_location;
      let data = {
        subsidiary_typeid,
        countryid,
        regionsid,
        districtsid,
        name,
        manager,
        description,
        phone,
        mobile_phone,
        physical_location,
      };
      // console.log("data:", data);
      let payload = {data: data, state: false};
      this.$emit("submitNew", payload);
    },
  },
};
</script>
